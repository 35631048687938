import { ref, computed, watch } from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import ability from "@/libs/acl/ability";
import { debounce } from "lodash";

export default function useList() {
   const { t } = useI18nUtils();
  const trans = {
    'messages.error.server_error': t('messages.error.server_error')
  };
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("unit/getAll");

  const unitOptions = computed(() => store.getters["unit/getSelectedItemsWithoutTenant"]);
  const unitFilter = ref(null);

  const tableColumns = [
    { label: "access_devices.name", key: "name", sortable: true },
    { label: "unit.title", key: "unit.description", sortable: true },
    { label: "access_devices.serial_number", key: "serial_number", sortable: true },
    { label: "access_devices.mac_address", key: "mac_address", sortable: true },
    { label: "access_devices.iot_id", key: "iot_id", sortable: true },
    { label: "access_devices.model", key: "model.description", sortable: true },
  ];

  if (
    ability.can("update", "AccessDevices") === true ||
    ability.can("delete", "AccessDevices") === true
  ) {
    tableColumns.splice(0, 0, { label: "actions.name", key: "actions" });
  }

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const action = ref("list");


  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("access_devices/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_unit: unitFilter.value,
        action: action.value,
      })
      .then((response) => {
        if (action.value == "export") {
          action.value = "list";

        } else {
          callback(response.data);
          totalList.value = response.meta.total;
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: trans['messages.error.server_error'],
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })

  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch([ unitFilter, currentPage, perPage ], () => {
    refetchData();
  });

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    unitOptions,
    unitFilter,
    refetchData,
  };
}
